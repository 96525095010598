import {Tooltip, TooltipProps} from '@mui/material';
import {FC} from 'react';

export type KPopoverProps = Omit<TooltipProps, 'open'> & {
  /** test id */
  'data-testid': string;
  /** show popover */
  isOpen?: boolean;
};

/**
 * Simple Popover wrapper extending PopoverProps.
 */
export const KPopover: FC<KPopoverProps> = ({children, isOpen, ...tooltipProps}) => (
  <Tooltip
    classes={{
      tooltip: 'popover',
      arrow: 'popover',
    }}
    // override the default tooltip max width of 300px to prevent it from not fully wrapping its children
    slotProps={{
      tooltip: {
        sx: {maxWidth: 'unset'},
      },
    }}
    {...tooltipProps}
    open={isOpen}
  >
    {/* .ref-wrapper is required for case when children is disabled and also helps nesting for nested menu */}
    <div className={'ref-wrapper'}>{children}</div>
  </Tooltip>
);
