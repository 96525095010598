import {
  Checkbox,
  CheckboxProps,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  MenuItemProps,
} from '@mui/material';
import clsx from 'clsx';
import Link from 'next/link';
import {FC, ReactNode} from 'react';

/** menu items props, note in order left to right placement */
export type KMenuItemProps = MenuItemProps & {
  /** test id */
  'data-testid': string;
  /** optional subheader with 100% width */
  subheader?: string;
  /** optional checkbox */
  checkbox?: CheckboxProps;
  /** optional icon */
  icon?: ReactNode;
  /** optional text */
  text?: string;
  /** optional link */
  href?: string;
  /** optional secondary text */
  secondary?: string;
  /** optional right icon */
  rightIcon?: ReactNode;
  /** route currently is href */
  isActive?: boolean;
  /** string type that replaces the default li component */
  component?: string;
  /**
   * Disables NextJS intelligent client-side navigation for the provided href and treats it as a traditional <a> tag.
   * This is useful for links to external websites or api routes such as logging out that do not return a NextJS page.
   */
  hrefIsNotNextJSPage?: boolean;
};

/**
 * MenuItem wrapper with left/right icon and href handling.
 *
 * ****************
 * NOTE: for SELECT must have children as text is not working.
 * ****************
 *
 */
export const KMenuItem: FC<KMenuItemProps> = ({
  text,
  secondary,
  href,
  icon,
  rightIcon,
  checkbox,
  subheader,
  isActive,
  children,
  hrefIsNotNextJSPage,
  ...menuItemProps
}) => {
  const tid = menuItemProps['data-testid'];

  return subheader ? (
    <ListSubheader data-testid={tid}>{subheader}</ListSubheader>
  ) : (
    <MenuItem
      {...menuItemProps}
      className={clsx(menuItemProps.className ?? '', {active: isActive})}
      onClick={menuItemProps.onClick}
      // The following properties force the Menu Item to render inside an <a> tag with the proper href when it is a link
      {...(href && {
        prefetch: hrefIsNotNextJSPage ? undefined : false,
        component: hrefIsNotNextJSPage ? 'a' : Link,
        href,
      })}
    >
      {checkbox !== undefined && (
        <ListItemIcon>
          <Checkbox edge="start" tabIndex={-1} disableRipple data-testid={`${tid}-checked`} {...checkbox} />
        </ListItemIcon>
      )}

      {icon && <ListItemIcon>{icon}</ListItemIcon>}

      {text && <ListItemText>{text}</ListItemText>}

      {children}

      {secondary && <ListItemText sx={{flexGrow: 'unset'}}>{secondary}</ListItemText>}

      {rightIcon && <ListItemIcon sx={{justifyContent: 'flex-end'}}>{rightIcon}</ListItemIcon>}
    </MenuItem>
  );
};

export const MapKMenuItemProps = (items: KMenuItemProps[]) =>
  items.map(({key, ...item}) => <KMenuItem key={key} {...item} />);
