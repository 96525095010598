'use client';
import {AlertColor} from '@mui/material';
import {PropsWithChildren, createContext, useContext, useReducer} from 'react';
import {KToast} from '../alerts/KToast';

enum GlobalUIActionTypes {
  ToggleMenu = 'TOGGLE_MENU',
  RaiseToast = 'RAISE_TOAST',
  CloseToast = 'CLOSE_TOAST',
}

export type GlobalUIToastProps = {
  text: string;
  isOpen: boolean;
  title: string;
  'data-testid': string;
  color: AlertColor;
};

const globalUIActionCreators = {
  toggleMainMenu: () => ({type: GlobalUIActionTypes.ToggleMenu}) as const,
  closeToast: () => ({type: GlobalUIActionTypes.CloseToast}) as const,
  raiseToast: (props: Omit<GlobalUIToastProps, 'isOpen'>) =>
    ({
      type: GlobalUIActionTypes.RaiseToast,
      props,
    }) as const,
} as const;

export type GlobalUIAction = ReturnType<(typeof globalUIActionCreators)[keyof typeof globalUIActionCreators]>;

/** The entire Global UI State that we expose in context */
type FullGlobalUIReducerState = {
  collapseMenu: boolean;
  inboxEnabled: boolean;
  toastProps: GlobalUIToastProps;
};

const initialState: FullGlobalUIReducerState = {
  collapseMenu: true,
  inboxEnabled: false,
  toastProps: {
    title: '',
    text: '',
    isOpen: false,
    color: 'info',
    'data-testid': 'toast',
  },
};

/** The portion of the Global UI State that we expose in context */
type GlobalUIContextState = Omit<FullGlobalUIReducerState, 'toastProps'>;

type ReplaceFnReturnType<T extends (...a: any[]) => any, R> = (...a: Parameters<T>) => R;
type Dispatcher<T extends {[key: string]: (...a: any[]) => any}> = {[K in keyof T]: ReplaceFnReturnType<T[K], void>};

const globalUIContext = createContext<{state: GlobalUIContextState} & Dispatcher<typeof globalUIActionCreators>>({
  state: initialState,
  ...globalUIActionCreators,
});

export function globalUIReducer(state: FullGlobalUIReducerState, action: GlobalUIAction): FullGlobalUIReducerState {
  switch (action.type) {
    case GlobalUIActionTypes.ToggleMenu:
      return {...state, collapseMenu: !state.collapseMenu};
    case GlobalUIActionTypes.RaiseToast:
      return {...state, toastProps: {...initialState.toastProps, ...action.props, isOpen: true}};
    case GlobalUIActionTypes.CloseToast:
      return {...state, toastProps: initialState.toastProps};
    default:
      return state;
  }
}

/** GlobalUIProvider provides context for top-level UI changes such as the expand and collapse of the side menu */
export const GlobalUIProvider = (props: PropsWithChildren<{inboxEnabled: boolean}>) => {
  const [state, dispatch] = useReducer(globalUIReducer, {...initialState, inboxEnabled: props.inboxEnabled});
  const {toastProps, ...exposedState} = state;

  return (
    <globalUIContext.Provider
      value={{
        state: exposedState,
        toggleMainMenu: () => dispatch(globalUIActionCreators.toggleMainMenu()),
        closeToast: () => dispatch(globalUIActionCreators.closeToast()),
        raiseToast: (props) => dispatch(globalUIActionCreators.raiseToast(props)),
      }}
    >
      {props.children}
      <KToast
        onClose={() => dispatch(globalUIActionCreators.closeToast())}
        variant={'outlined'}
        color={toastProps.color || 'success'}
        isOpen={toastProps.isOpen}
        severity={toastProps.color}
        title={toastProps.title}
        data-testid={toastProps['data-testid']}
      >
        {toastProps.text}
      </KToast>
    </globalUIContext.Provider>
  );
};

export const useGlobalUIContext = () => useContext(globalUIContext);
