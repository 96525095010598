import {KCallout, KCalloutProps} from '@/components/alerts/KCallout';
import {Paper, Snackbar, SnackbarOrigin, SnackbarProps} from '@mui/material';
import {PropsWithChildren} from 'react';

/** KToast props extending SnackbarProps */
export type KToastProps = KCalloutProps & {
  /** testid */
  'data-testid': string;
  /** display */
  isOpen: boolean;
  /** onClose event handler */
  onClose: (reason: string) => void;

  anchorOrigin?: SnackbarOrigin;

  autoHideDuration?: number | null;

  snackbarProps?: SnackbarProps;
};

const defaultDuration = 10000;
const defaultOrigin: SnackbarOrigin = {horizontal: 'right', vertical: 'top'};

/**
 * Toast message using Snackbar and KCallout.
 *
 * Set isOpen to true to display at anchor origin for duration.
 *
 * Defaults to right/top anchor origin and 6s duration.
 *
 */
export const KToast = ({
  isOpen,
  onClose,
  anchorOrigin = defaultOrigin,
  autoHideDuration = defaultDuration,
  children,
  snackbarProps,
  ...calloutProps
}: PropsWithChildren<KToastProps>) => (
  <Snackbar
    anchorOrigin={anchorOrigin}
    autoHideDuration={autoHideDuration}
    {...snackbarProps}
    open={isOpen}
    onClose={(ev, reason) => onClose(reason)}
  >
    <Paper>
      <KCallout variant={'filled'} {...calloutProps}>
        {children}
      </KCallout>
    </Paper>
  </Snackbar>
);
