import {Alert, AlertProps, AlertTitle} from '@mui/material';
import {PropsWithChildren} from 'react';

export type KCalloutProps = AlertProps & {
  /** testid */
  'data-testid': string;
};

/**
 * Alert wrapper with title, text and severity icon with outlined
 * and filled variants which defaults to outlined.
 *
 * Optional children are appended after the title and text.
 */
export const KCallout = ({title, children, ...alertProps}: PropsWithChildren<KCalloutProps>) => {
  const tid = alertProps['data-testid'];

  return (
    <Alert variant="outlined" {...alertProps}>
      {title && <AlertTitle data-testid={`${tid}-title`}>{title}</AlertTitle>}
      {children && <div data-testid={`${tid}-text`}>{children}</div>}
    </Alert>
  );
};
