const colors = {
  black: '#000000',
  calcite: '#fff',
  darkBlueGrey: '#2b2352',
  darkBlueGreyAlpha50: '#9591A9',
  veryLightBlue: '#e7ecf5',
  cobalt: 'rgb(32, 63, 131)',
  cobaltDisabled: 'rgba(32, 63, 131, 0.5)',
  cobaltHover: 'rgba(32, 63, 131, 0.75)',
  cobaltLight: 'rgba(32, 63, 131, 0.2)',
  cobaltLightHover: 'rgba(32, 63, 131, 0.1)',
  darkIndigo: 'rgb(18, 40, 88)',
  frenchBlue: 'rgb(60, 92, 170)',
  lightBlue: '#1592FF',
  darkSlateBlue: 'rgb(18, 38, 80)',
  lightTableRowEven: 'rgb(245, 248, 250)',
  lightTableRowOdd: 'rgba(191, 204, 214, 0.2)',
  lightDividerBlack: 'rgba(16, 22, 26, 0.15)',
  inactive: 'rgba(16, 22, 26, 0.25)',
  dark: 'rgb(24, 32, 38)',
  lightIconDefault: 'rgb(92, 112, 128)',
  lightIconDisabled: 'rgba(92, 112, 128, 0.5)',
  lightIconWhite: 'rgb(255, 255, 255)',
  lightIconSuccess: 'rgb(13, 128, 80)',
  lightIconDangerDarker: 'rgb(194, 48, 48)',
  lightBadges7PrimaryMinimal: 'rgba(19, 124, 189, 0.2)',
  lightBadges6DefaultMinimal: 'rgba(138, 155, 168, 0.2)',
  lightBadges8SuccessMinimal: 'rgba(15, 153, 96, 0.2)',
  lightBadges3Success: 'rgb(15, 153, 96)',
  lightBadges4Warning: 'rgb(217, 130, 43)',
  lightIconWarning: 'rgb(191, 115, 38)',
  lightIconDanger: 'rgb(222, 67, 67)',
  lightBadges10DangerMinimal: 'rgba(219, 55, 55, 0.2)',
  lightBadges9WarningMinimal: 'rgba(217, 130, 43, 0.2)',
  lightBadges5Danger: 'rgb(219, 55, 55)',
  lightIconStarSelected: 'rgb(242, 184, 36)',
  lightDatePickerRange: 'rgb(235, 241, 245)',

  pageBgColor: 'rgb(249, 247, 254)',
  navBarBgColor: 'rgb(41, 35, 79)',
  activateBgColor: 'rgba(24, 32, 38, 0.33)',

  lightTeal: '#8AC7CB',
  darkTeal: 'rgb(0, 152, 158)',
  primaryPlum: '#ab2869',
  primaryDarkBlue: '#3C5DAA',
  group: 'rgb(0, 142, 188)',
  grouped: 'rgba(0, 152, 158, 0.5)',
  task: 'rgb(0, 152, 158)',
  taskGrouped: 'rgba(0, 152, 158, 0.5)',
  wear: 'rgb(171, 40, 105)',
  wearGrouped: 'rgba(171, 40, 105, 0.5)',
  outstanding: '#ff9e15',

  statusLightBlue: '#008ebc',
  statusRed: '#ab2869',
  statusGreen: '#0f9960',
  statusBlack: '#182026',
  statusOrange: '#ff9500',
  linerProgressTrack: '#e3e3e3',
  now: 'rgb(234, 67, 53)',
  pagination: '#738c9a',
  notificationText: '#3f536e',
  placeholder: 'rgb(155, 155, 155)',
} as const;

const coloroutputs = {
  ...colors,
  inherit: 'transparent',
  primary: colors.dark,
  info: colors.frenchBlue,
  secondary: colors.inactive,
  warning: colors.lightBadges4Warning,
  error: colors.lightBadges5Danger,
  success: colors.lightBadges3Success,
  compliant: colors.lightBadges3Success,
  nonCompliant: colors.lightBadges5Danger,
  draft: colors.statusLightBlue,
  uat: colors.statusRed,
  active: colors.statusGreen,
  completed: colors.statusBlack,
  visit: colors.lightBlue,
  noComplianceThreshold: colors.cobalt,
};

export default coloroutputs;
