import {ClickAwayListener, Tooltip, TooltipProps} from '@mui/material';
import {FC, useState} from 'react';

export type KTooltipProps = Omit<TooltipProps, 'open'> & {
  'data-testid': string;
  isOpen?: boolean;
  isClickToOpen?: boolean;
};

/**
 * Simple Tooltip wrapper extending TooltipProps.
 *
 * Note: <div> wrapper of children as MUI needs a ref-able element.
 */
export const KTooltip: FC<KTooltipProps> = ({children, isOpen: defaultIsOpen, isClickToOpen, ...tooltipProps}) => {
  const [isOpen, setIsOpen] = useState<boolean | undefined>(defaultIsOpen ?? (isClickToOpen ? false : undefined));

  const clickable = isClickToOpen
    ? {
        onClick: () => setIsOpen(!isOpen),
        disableHoverListener: true,
      }
    : {
        enterNextDelay: 200,
        leaveDelay: 200,
      };

  const tooltip = (
    <Tooltip {...clickable} {...tooltipProps} open={isOpen}>
      <div>{children}</div>
    </Tooltip>
  );

  return (
    <>
      {!isClickToOpen ? (
        tooltip
      ) : (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <div className={'referencable'} data-testid={'tooltip-container'}>
            {tooltip}
          </div>
        </ClickAwayListener>
      )}
    </>
  );
};
