'use client';
import {Stack, StackProps} from '@mui/material';
import clsx from 'clsx';
import type {PropsWithChildren} from 'react';

export type KStackProps = StackProps & {
  /** optional set --childWidth to value */
  childWidth?: string;
  /** optional set --childHeight to value */
  childHeight?: string;
  /** optional set --inputsWidth to value */
  inputsWidth?: string;
  /** optional add className inputsRowChildren for form formatting */
  isFormLayout?: boolean;
};

/**
 * Simple direct Stack wrapper for MUI Stack using StackProps.
 *
 * Optional isFormLayout to add class inputsRowChildren for form formatting.
 *
 * Optional childWidth for width of flexbox items.
 *
 * Optional childHeight for height of flexbox items.
 *
 * Optional inputsWidth for width of input elements.
 *
 * @param {string | undefined} childWidth
 * @param {string | undefined} childHeight
 * @param {string | undefined} inputsWidth
 * @param {boolean | undefined} isFormLayout
 * @param {StackProps} stackProps
 * @returns {JSX.Element}
 * @constructor
 */
export const KStack = ({
  childWidth,
  childHeight,
  inputsWidth,
  isFormLayout,
  children,
  ...stackProps
}: PropsWithChildren<KStackProps>) => {
  const className = clsx(
    stackProps.className,
    childWidth && 'set-child-width',
    childHeight && 'set-child-height',
    isFormLayout && 'inputsRowChildren',
  );
  const style = {
    ...stackProps.style,
    ['--childWidth' as any]: childWidth,
    ['--inputsWidth' as any]: inputsWidth,
    ['--childHeight' as any]: childHeight,
  };

  return (
    <Stack direction={'column'} {...stackProps} className={className} style={style}>
      {children}
    </Stack>
  );
};
