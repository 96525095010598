'use client';
import {MenuList, MenuListProps} from '@mui/material';
import clsx from 'clsx';
import {FC} from 'react';

import {KMenuItem, KMenuItemProps} from './KMenuItem';

/** menu list props */
export type KMenuListProps = Omit<MenuListProps, 'children'> & {
  /** menu items */
  items: KMenuItemProps[];
  /** optional horizontal menu */
  isRow?: boolean;
  /** optional tabs menu theme */
  isTabs?: boolean;
  /** optional menu no box shadows theme */
  isFlat?: boolean;
  /** optional index of active menu item */
  activeIndex?: number;
};

/**
 * Displays a menu with array of items using MenuList and KMenuItem.
 *
 * With isRow as true, items will be displayed horizontally with
 * css divider as defined in theme.
 *
 * With isTabs as true, list will add 'tabs' class name for theming.
 *
 * menuListProps optional props applied to MenuList container.
 *
 * To set color of menu items text, use
 *   style={{'--menuItemColor': color}}
 * to override theme setting for text color.
 *
 * Subheader, checkbox, left icon, text, secondary text and right icon
 * can be defined for each item individually.
 *
 * Note: MenuList extends List.
 *
 * @param {KMenuItemProps[]} items
 * @param {boolean | undefined} isRow
 * @param {boolean | undefined} isTabs
 * @param {MenuListProps} menuListProps
 * @returns {JSX.Element}
 * @constructor
 */
export const KMenuList: FC<KMenuListProps> = ({items, isRow, isTabs, isFlat, activeIndex, ...menuListProps}) => (
  <MenuList {...menuListProps} className={clsx(menuListProps.className, {row: isRow, tabs: isTabs, flat: isFlat})}>
    {items.map(({children, ...menuItemProps}, index) => (
      <KMenuItem key={index} {...menuItemProps} isActive={index === activeIndex}>
        {children}
      </KMenuItem>
    ))}
  </MenuList>
);
