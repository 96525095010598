import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Card,
  CardActions,
  CardActionsProps,
  CardContent,
  CardContentProps,
  CardHeader,
  CardHeaderProps,
  CardProps,
  IconButton,
} from '@mui/material';
import clsx from 'clsx';
import {FC, ReactNode} from 'react';
import {KRow} from './KRow';

export type KCardVariantType =
  | 'elevation'
  | 'outlined'
  | 'popover'
  | 'dialog'
  | 'menu'
  | 'panel'
  | 'summary'
  | 'form'
  | 'form-grow'
  | 'htmlEditor'
  | string;
export type KCardSeverityType = 'info' | 'success' | 'warning' | 'error' | string;
export type KCardIconMappingType = Partial<Record<KCardSeverityType, ReactNode>>;

/**
 * KCard props extending CardProps.
 * Note CardProps also extends PaperProps.
 */
export type KCardProps = Omit<CardProps, 'title' | 'variant'> & {
  /** testid */
  'data-testid': string;
  /** card variant with 'popover' and 'dialog' variants added */
  variant: KCardVariantType;
  /** optional display severity icon to left */
  severity?: KCardSeverityType;
  /** optional icon mapping used with severity */
  iconMapping?: KCardIconMappingType;
  /** optional fill height */
  fill?: boolean;
  /** optional scroll card content */
  scrollContent?: boolean;
  /** optional text for CardContent */
  text?: string;
  /** optional title for CardHeader */
  title?: ReactNode;
  /** optional avatar for CardHeader */
  titleIcon?: ReactNode;
  /** optional add close icon event header CardHeader */
  onClose?: () => void;
  /** optional event header actions */
  headerActions?: ReactNode;
  /** optional actions for CardActions */
  actions?: ReactNode;
  /** optionally just define a Card directly [or any ReactNode] */
  children?: ReactNode;
  /** optional header props */
  headerProps?: CardHeaderProps;
  /** optional content props */
  contentProps?: CardContentProps;
  /** optional content title props */
  contentTitleProps?: CardContentProps;
  /** optional action props */
  actionsProps?: CardActionsProps;
  /** optional card width */
  width?: string;
  /** optional card height */
  height?: string;
  /** optional flat with no box shadows */
  flat?: boolean;
};

/**
 * Card, CardHeader, CardContent and CardActions wrapper
 * with severity props added.
 *
 * Used with KDialog, KPopover and KPanel to define contents.
 *
 * Use width to override default width var(--dialogWidth)
 * Use fill to stretch height to available vertical space.
 * Use scrollContent to scroll content.
 *
 * Variants that add theme styling:
 * - popover - use with KPopover
 * - dialog - use with KDialog
 * - menu - use with KMenu
 * - panel - use with KPanel and also applies fill and scrollContent styling
 * - summary - use with KSummaries
 * - form - use to style forms
 * - form-grow - use to style forms and fill horizontally
 *
 * @param {KCardProps} props
 * @returns {JSX.Element}
 * @constructor
 * @see CardProps
 * @see CardHeaderProps
 * @see CardContentProps
 * @see CardActionsProps
 */
export const KCard: FC<KCardProps> = ({
  variant,
  fill,
  scrollContent,
  severity,
  text,
  title,
  titleIcon,
  actions,
  onClose,
  headerActions,
  iconMapping = {
    error: <InfoIcon fontSize={'large'} />,
    info: <InfoIcon />,
    success: <CheckIcon />,
    warning: <WarningIcon />,
  } as KCardIconMappingType,
  children,
  headerProps,
  contentProps,
  contentTitleProps,
  actionsProps,
  width,
  height,
  ...cardProps
}) => {
  const tid = cardProps['data-testid'];
  const icon: ReactNode = severity ? iconMapping[severity] : undefined;

  return (
    <Card
      /* todo resolve any bc variant extended with 'popover' and 'dialog' */
      variant={variant as any}
      classes={{...cardProps.classes, root: clsx(fill && 'fill', scrollContent && 'scrollContent')}}
      {...cardProps}
      sx={{width, height, ...cardProps.sx}}
    >
      {title && (
        <CardHeader
          {...headerProps}
          avatar={titleIcon}
          title={title}
          action={
            <>
              {headerActions}
              {onClose && (
                <IconButton onClick={onClose} data-testid={`${tid}-close`}>
                  <CloseIcon />
                </IconButton>
              )}
            </>
          }
        />
      )}

      <CardContent
        {...contentProps}
        classes={{root: clsx(contentProps?.classes, fill && 'fill', scrollContent && 'scrollContent')}}
      >
        {(icon || text) && (
          <KRow gap={2} {...contentTitleProps}>
            {icon && <div>{icon}</div>}

            {text && <div>{text}</div>}
          </KRow>
        )}

        {children}

        {actions && (
          <CardActions {...actionsProps} sx={{...actionsProps?.sx, justifyContent: 'flex-end'}}>
            {actions}
          </CardActions>
        )}
      </CardContent>
    </Card>
  );
};
