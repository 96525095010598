import {KStack, KStackProps} from '@/components/layout/KStack';
import {FC} from 'react';
import {KTag} from './KTag';
import {KTagType} from './types';

export type KTagsProps = KStackProps & {
  /** testid */
  'data-testid': string;
  /** display tags with selected state */
  tags: KTagType[];
  /** tag selected */
  onChangeTag: (tag: KTagType, index: number) => void;
  maxTagsVisible?: number;
};

/**
 * Display static list of tags with enabled change event.
 */
export const KTags: FC<KTagsProps> = ({children, tags, onChangeTag, maxTagsVisible, ...stackProps}) => (
  <KStack direction="row" gap={2} {...stackProps}>
    {[
      ...(maxTagsVisible ? tags.slice(0, maxTagsVisible) : tags).map((tag, index) => (
        <KTag
          key={tag.id}
          onChange={(tag) => {
            onChangeTag(tag, index);
          }}
          {...tag}
        />
      )),
      ...(maxTagsVisible && tags.length > maxTagsVisible
        ? [
            <KTag
              id={'additional'}
              key="additional-tags-label"
              label={`+${tags.length - maxTagsVisible}`}
              chipProps={{style: {marginTop: -2, marginBottom: 0}}}
              removable={false}
              selected
            />,
          ]
        : []),
    ]}
    {children}
  </KStack>
);
