import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {Chip} from '@mui/material';
import {FC} from 'react';

import {KTooltip} from '@/components/alerts/KTooltip';
import {KTagType} from './types';

export type KTagProps = KTagType & {
  onChange?: (tag: KTagType) => void;
  characterLimit?: number;
  visibleCharactersAtLimit?: number;
};

/**
 * Displays KTagType tag using Chip and handles toggling enabled
 * property. Extends ChipProps for customizing chip.
 * Wraps chip in KTooltip using tooltipProps if it exists.
 */
export const KTag: FC<KTagProps> = ({onChange, characterLimit, visibleCharactersAtLimit, tooltipProps, ...tag}) => {
  const onToggleSelected = (tag: KTagType) => {
    onChange?.({...tag, selected: !tag.selected});
  };

  const chip = (
    <Chip
      label={
        characterLimit && typeof tag.label === 'string' && tag.label.length > characterLimit
          ? `${tag.label.slice(0, visibleCharactersAtLimit ?? characterLimit)}...`
          : tag.label
      }
      color={tag.color ?? 'primary'}
      variant={tag.selected ? 'filled' : 'outlined'}
      deleteIcon={tag.selected ? <CloseIcon /> : <AddIcon />}
      onDelete={tag.removable ? () => onToggleSelected(tag) : undefined}
      onClick={tag.removable ? () => onToggleSelected(tag) : undefined}
      data-testid={tag['data-testid']}
      data-testselected={tag.selected}
      {...tag.chipProps}
    />
  );

  return tooltipProps ? <KTooltip {...tooltipProps}>{chip}</KTooltip> : chip;
};
