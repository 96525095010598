import {Button, ButtonProps} from '@mui/material';

export type KButtonProps = Pick<
  ButtonProps,
  'color' | 'startIcon' | 'endIcon' | 'onClick' | 'disabled' | 'sx' | 'variant' | 'size' | 'className' | 'component'
> & {
  'data-testid': string;
  buttonText: string;
};

export function KButton({'data-testid': tid, color = 'primary', buttonText, ...rest}: KButtonProps) {
  const isTextVariant = rest.variant === 'text';
  return (
    <Button
      color={color}
      data-testid={tid}
      disableRipple={isTextVariant}
      style={isTextVariant ? {backgroundColor: 'transparent', fontSize: '1rem'} : undefined}
      {...rest}
    >
      {buttonText}
    </Button>
  );
}
