'use client';
import type {PropsWithChildren} from 'react';
import {KStack, KStackProps} from './KStack';

export type KRowProps = KStackProps;

/**
 * Simple direct KStack wrapper for direction = 'row' props that
 * get repeatedly used.
 *
 * Set width={'100%} if full width desired.
 *
 */
export const KRow = ({children, ...stackProps}: PropsWithChildren<KStackProps>) => (
  <KStack direction={'row'} justifyContent={'space-between'} alignItems={'center'} {...stackProps}>
    {children}
  </KStack>
);
